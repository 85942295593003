import {BI_ENDPOINTS, isDateTbd, isLocationTbd} from '@wix/wix-events-commons-statics'
import {AnyAction} from 'redux'
import {EventMap} from '../../../../commons/bi/interfaces'
import {createEvent} from '../actions/event'
import {State} from '../types/state'

export const eventMap: EventMap = {
  [createEvent.pending.toString()]: (_state: State, action: AnyAction) => {
    if (createEvent.pending.match(action)) {
      const args = action.meta.arg
      const adi = Boolean(args.ADI)

      return {
        evid: 103,
        event_id: null as string,
        create_event_button: adi ? 'ADI' : 'save',
        is_first_event_save: true,
        is_adi_intro: adi,
        is_time_tbd: Boolean(args?.data?.event?.scheduleConfig?.scheduleTbd),
        is_location_tbd: Boolean(!args?.data?.event?.location?.address),
      }
    }
  },
  [createEvent.fulfilled.toString()]: (_state: State, action: AnyAction) => {
    if (createEvent.fulfilled.match(action)) {
      const {
        event: {
          id,
          title,
          scheduling: {config},
          mainImage,
        },
        ADI,
      } = action.payload

      return {
        evid: 104,
        event_id: id,
        event_name: title,
        event_start_date: config.scheduleTbd ? null : new Date(config.startDate).getTime(),
        event_finish_date: config.scheduleTbd || !config.endDate ? null : new Date(config.endDate).getTime(),
        event_timezone: config.scheduleTbd ? null : config.timeZoneId,
        create_event_button: ADI ? 'ADI' : 'save',
        with_image: !!(mainImage && mainImage.id),
        is_first_event_save: true,
        is_adi_intro: ADI,
        draft_event: false,
        is_time_tbd: isDateTbd(action.payload.event),
        is_location_tbd: isLocationTbd(action.payload.event),
      }
    }
  },
  endpoint: BI_ENDPOINTS.EVENTS_USERS,
}
